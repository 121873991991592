.ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: green;
  display:none;
}

.li {
  float: left;
}

.li.nav-item:not(:hover) {
  padding-left: 4px;
}

.li .link:hover {
  background: #252831;
  border-left: 4px solid green;
  cursor: pointer;
}

.li .link {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.li .active{
  color:#8bdd1f;
}

.active{
  color:#8bdd1f;
}

.inactive{
  color:#f6f8f2;
}


