.loading {
  text-align: center;
  position: relative;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: bold;
}

.not-authorized {
  position: relative;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}