.greyBg{
    background-color: #F9F9F9;
    flex-grow: 1;
}

.homeBanner{
    height:300px;
    background-color: #203143;
    margin-bottom: 0px;
    background-image: url("../../images/hero-bg.png");
    background-size: cover;
}

.homeBanner h1{
    color:#fff;
    font-weight: 700;
    margin:0px;
    padding-top:40px;
}

.homeBanner h1 span{
    font-weight: 300;
}

.reportingLink{
    background-color:#009949;
}

.certLink{
    background-color:#7ABA44;
}

.helpLink{
    background-color:#F1D244;
}

.homeLink{
    position: relative;
}

.homeLink span{
    display:block;
    position: absolute;
    left:0px;
    right:0px;
    bottom:0px;
    padding:10px;
    background-color: rgba(255,255,255,0.5);
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
}

.homeLinks a{
    color:#343434;
    font-size: 16px;
    line-height: 30px;
    font-weight: bold;
    text-decoration: none;
}

.homeLinks a:hover{
    color:#343434;
    text-decoration: underline;
}

.homeTabContainer {
    border: solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    border-width: var(--bs-border-width);
}

.homeTabs button.nav-link {
    color:#343434;
    font-size: 16px;
    line-height: 30px;
    font-weight: bold;
    text-decoration: none;
    border-top: none;
}

.homeTabs button.nav-link:not(.active) {
    border-left: none;
    border-right: none;
}

.homeTabs button.nav-link.active {
    border-bottom-color: var(--bs-border-color);
}

.homeTabs button:hover{
    color:#343434;
    text-decoration: underline;
    border-top: none;
}

.homeTabContainer .tabContent{
    background-color: var(--bs-tertiary-bg);
}
