div.active {
    color: #FED41D;
}

div.fill-padding {
    width: 100%;
    height: 100%;
}

div.close-container {
    float: right;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    list-style: 60px;
    text-decoration: none;
    font-size: 18px;
    margin-bottom: 5px;
}

div.sidebar-button {
    width: 40px;
    height: 40px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    font-size: x-large;
    padding-bottom: 5px;
}

div.sidebar-button:hover {
    background: #203143;
    cursor: pointer;
    color:#FED41D;
}

div.sidebar-min {
    height: 100%;
    background-color: #203143;
    padding: 5px;
}

div.sidebar-header {
    color: #9198A1;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: bold;
    padding-left:20px;
    padding-top:20px;
}