.MultipleElementFilter {
    cursor: pointer;
}

.MultipleElementFilter > div {
    position: relative;
    border: 1px solid #000;
    width: 100%;
}

.MultipleElementFilter.active-filters > div {
    background-color: white !important;
}

.MultipleElementFilter.active-filters > div span {
    background-color: white !important;
}

.MultipleElementFilter span {
    padding-left: 5px;
}

.MultipleElementFilter button {
    border-radius: 20px;
    background-color: #343434 !important;
    color: #fff;
    border: none;
    line-height: 30px;
    padding: 5px 10px;
    margin: 0px 5px;
}

.MultipleElementFilter .expanded {
    position: absolute;
    top: 100%;
    z-index: 999;
    background-color: white;
    padding: 10px;
    border: 1px solid #000;
    width: 100%;
}

.MultipleElementFilter .expanded label:hover {
    background-color: gray !important;
}

.MultipleElementFilter label {
    display: block;
    width: 100%;
    background-color: white !important;
}

.MultipleElementFilter label input[type='checkbox'] {
    margin: 5px;
}