* {
  box-sizing: border-box;
}

.forensic-root {
  margin: 10px;
}

.forensic-form {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.forensic-label {
  display: inline;
  float: left;
  color: black;
}

.form-input {
  display: inline;
  float: left;
  color: black;
  padding: 0px 10px;
}

.form-button {
  display: inline;
  float: left;
  color: black;
}
