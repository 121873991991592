.column1 {
  float: left;
  width: 20%;
  padding: 10px;
  height: 300px;
}

.column2 {
  background-color: greenyellow;
  float: left;
  width: 80%;
  padding: 10px;
  height: 300px;
  margin-top: 10px;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.flex-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-height: 100vh;
}

#content-container {
  height: 100%;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

#fixed-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
}

#error-popup {
  position: fixed;
  bottom: 60px;
  right: 20px;
  z-index: 100;
}

.form-label{
    font-size: 20px;
    font-weight: 700;
    margin-bottom:20px;
}

div.row-portal {
  display: flex;
  flex-direction: row;
  min-width: 100vw;
  max-width: 100vw;
  flex-grow: 1;
  min-height: 0;
}

.column1-portal {
  float: left;
}

.column2-portal {
  float: right;
  width: 100%; 
  overflow: scroll;
  padding: 40px;
  background-color: #F9F9F9;
}

.row-portal:after {
  content: "";
  column-gap: 10px;
  display: table;
  clear: both;
}