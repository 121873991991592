.wrap {
    max-width: 100%; 
    overflow-x: auto;
    clear:both;
    filter: drop-shadow(1px 1px 2px rgba(0,0,0,0.5));
    margin-bottom:40px;
    border:1px #bbb solid;
  }

  .tableTopNav{
    float:right;
    margin-bottom:40px;
    margin-top:-55px;
    white-space: nowrap;
  }

  .formButton{
    border-radius: 20px;
    background-color:#343434;
    color:#fff;
    border:none;
    line-height: 30px;
    padding: 5px 30px;
    margin: 5px;
  }

  .pageButtons{
    border-radius: 20px;
    background-color:#343434;
    color:#fff;
    border:none;
    width:40px;
    line-height:40px;
    font-size: 22px;
    font-weight: 700;
  }

  .pageButtons span{
    display: block;
    position: relative;
    top: -2px;
  }

  .pageButtons:hover,
  .excelButton:hover{
    background-color:#666;
  }

  .pageButtons:disabled{
    background-color:#ccc;
  }

  .showPerPage{
    margin-right: 10px;
    width:150px;
  }

  .goToPage{
    width:150px;
    margin: 0px 20px;
  }

  .tableHeaderDown{
    width:19px;
    height:19px;
    background-image: url("../../images/down-chevron.png");
    background-size: contain;
    background-repeat: no-repeat;
    display:inline-block;
    vertical-align: middle;
  }

.global-filter{
    font-size: 14px;
    background: transparent;
    border: none;
    color: #fff !important;
    outline: none;
    border-bottom: 1px #fff solid;
    background-image: url("../../images/search-icon.png");
    background-size: 22px;
    background-repeat: no-repeat;
    padding-left:24px;
    opacity: 1;
    width:440px;
}

.searchBox{
    float:left;
    min-width: 400px;
}

.searchButton{
    background-image: url("../../images/search-icon.png");
    background-size: 22px;
    background-repeat: no-repeat;
    background-position: center center;
    width:38px;
    height:38px;
    border-radius: 20px;
    padding:0px;
    float:left;
    margin-left:10px;
}

.sortArrow{
    font-size:12px;
    opacity: 0.75;
}

.resizer {
  display: inline-block;
  width: 10px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  touch-action: none;
  cursor: col-resize;
}

.isResizing {
  background: green; 
}