.darkBG {
  background-color: rgba(0, 0, 0, 0.75);
  width: 100vw;
  height: 100vh;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  position: fixed;
}

.centered {
  position: fixed;
  top: 40px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 11;
}

.mymodal {
  width: 600px;
  max-height: 600px;
  overflow: scroll;
  background: white;
  color: white;
  border-radius: 10px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  position: relative;
  padding:40px;
}
  
.heading {
  margin:0;
  padding: 10px;
  color: #2c3e50;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}

.modalContent {
  padding: 10px;
  font-size: 14px;
  color: #2c3e50;
  text-align: left;
}
