* {
  box-sizing: border-box;
}

input[type="text"],
select {
  width: 30%;
  padding: 12 px 12px 12px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

label {
  padding: 12 px 12px 12px 0;
  display: inline-block;
}

input[type="submit"] {
  background-color: #4caf50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float: centre;
}

input[type="submit"]:hover {
  background-color: #45a049;
}

.container {
  border-radius: 5px;
  padding: 20px;
}

.col-25 {
  color: black;
  float: left;
  height: 50px;
  width: 25%;
  margin-top: 6px;
  margin-bottom: 6px;
  font-weight: bold;
}

.col-75 {
  color: black;
  float: left;
  height: 50px;
  width: 65%;
  margin-top: 6px;
  margin-bottom: 6px;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (max-width: 600px) {
  .col-25,
  .col-75,
  input[type="submit"] {
    width: 100%;
    margin-top: 0;
  }
}
