.title {
  color: green;
  font-size: 24px;
  text-align: center;
}

.t1 {
  /*padding-bottom: 25px;*/
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
}

div.child-left {
  float: left;
  display: flex;
  align-items: center;
}

div.child-right {
  float: right;
}

img.logo {
  margin: 20px 30px;
}

.header-btn {
  background-color: #fff;
  border-color: #ccc;
  float: right;
  height: 40px; 
  margin-right:30px !important;
}

.header-btn:hover {
  background-color: white;
  border-color: #090;
  color: #090;
}

.header-btn::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}