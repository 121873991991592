.popup-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.popup-container {
    position: relative; /* so the hitbox will be positioned relative to the container */
    margin-top: 2px;
    display: flex;
    flex-direction: column;
    min-height: 100px;
}

.popup-header {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.popup-icon {
    margin-right: 10px;
}

.popup-body {
    flex-grow: 1;
}

.popup-timeout {
    height: 1px;
    border: none;
}

.popup-hitbox {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.popup-close {
    position: absolute;
    top: 8px;
    right: 12px;
    width: 21px;
    height: 21px;
    cursor: pointer;
}

.icon-info {
    color: blue;
}

.icon-warn {
    color: yellow;
}

.icon-error {
    color: red;
}

.icon-debug {
    color: green;
}