.title2 {
  font-size: 12px;
  font-weight: 300;
  text-align: left;
  padding-bottom: 0px;
}

.t2 {
  border-top:1px #ccc solid;
  background-color: #fff;
  text-align: left;
  padding:20px;
  width: 100%;
  left: 0;
  bottom: 0;
  margin-bottom: 0;
  z-index: 100;
  color: #6c757d;
  background: linear-gradient(0deg, rgba(255,255,255,1) 90%, rgba(204,204,204,0.2) 100%);
}

a.plain {
  text-decoration: none;
  color: #6c757d;
}

a.plain:hover {
    text-decoration: underline;
  }