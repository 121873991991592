table,
th,
td {
  border: 1px solid;
  padding: 10px;
}

table {
  width: 100%;
  padding: 15px;
}

tr:hover {
  background-color: #eaf2de;
}

th {
  background-color: green;
  color: white;
}

td {
  height: 50px;
  vertical-align: center;
}
